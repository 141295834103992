// sprechenundlernen

.team-col-1 img {
    display: block;
    margin: auto;
    width: 100%;
    height: auto;
}
#team img {
    max-width: 400px;  //303px
}
.not-front .panel-panel.middle {
    border-top: solid #71bf00 0.17em;
}

.navbar-header  //,#site-logo 
{
  float: right;
  margin: -1em -1.3em 0 0;	
}
@media (max-width: @screen-xs-max) {
.navbar-header {
    width:100%;
}
.navbar-nav {
    margin: 0;
}
}
.navbar-header button {
    float: left;
    margin-top: 10px;
}
#navbar-collapse {
    min-width: 280px;
}
.bootstrap-twocol-stacked .row .left {
	img {
    	width: 100%;
	}
	.mvv-monitor-header {
		background-color: @baja-green;
		img {
			width: auto;
		}
	}
	.mvv-monitor-footer-content {
		background-color: @baja-green;
	}
}
header .logo {
    float: right !important;
}
.navbar-collapse {
    padding: 0;
}
.navbar-default {
    background-color: white;
    border: none;
}
nav li {
    list-style-type: none;
}
nav li a,
.secondary li a,
.navbar-default .navbar-nav > li > a:focus{
    text-transform: uppercase;
    text-decoration: none;
    color: @baja-black;
}
@media (min-width: @screen-sm-min) {
.secondary li a {
    padding: 5px 6px;
}
.secondary li::before {
    content: "|";
    float: left;
}
.secondary li.first::before {
    content: "";
}
}
nav li a:hover,
nav li a.active,
.secondary li a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .active  > a:hover,
.navbar-default .navbar-nav > .active > a {
    text-decoration: none;
    color: @baja-blue;
    background-color: transparent;
    backgroud: none;
}
/*
@media (max-width: @screen-sm-min) {
    nav li a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
*/
nav .primary ul {
    width: auto;
}
nav .primary li a {
    display: block;
    width: auto;
}

@media (min-width: @screen-sm-min) {
nav .secondary {
    position: absolute; 
    right: 50px;
    text-align: right;
    top: 6.8em;
    font-size: 0.88em;
}
}
.anchor,
.anchor:hover{
    text-decoration:none;
 //   pointer-events: none;
    cursor: default;
    color: #71bf00;
}
#car {
	margin: 0 0 -0.2em -0.6em;
}
#car-wrapper {
	border-bottom:solid 0.17em #71bf00;
	width:88%;
}
#footer-address {	
	padding-top: 0.4em;
}
@media (max-width: @screen-xs-max) {
    #car {
	margin: 0 0 -0.2em 0em;
    }
    #footer-address {	
	padding-left: 0.3em;
    }
}
#telephone {
     float:right;
     padding-right:1em;
}
@media (min-width: @screen-md-min) {
    #telephone {
     margin-top:-3em;
    }
    .not-front .panel-panel.right {
    border-top: solid #71bf00 0.17em;
    }
}
@media (max-width: @screen-md-min) {
    #telephone {
     margin-top: 0em;
    }
}

header nav //#header #nav 
{
	float: left;
}

header nav ul {
  list-style-type: none;
  max-width: 315px;
  padding-top: 3.4em;
}

header nav ul a {
  outline: none;
}
@media (min-width: @screen-sm-min) {
.nav > li > a {
      padding-top: 0;
      padding-bottom: 0;
}
}

#nav .vlist ul,
#nav .vlist li {
  margin-left: 0em;
}

#nav-bar {
	border:none;
}

#main {
	padding: 0em;
}

#col3_content {
  padding: 0em;
}

#header {
	height: 7.4em;
	background: none;
	padding: 3.4em 2em 1em 1em;
}

body {
	background: #ffffff;
  color: #3e3e3e;
  font-size: 1.2em;
}
h2 {
  font-family: Arial, Helvetia, sans-serif;
  color: #71bf00;
  text-transform:uppercase;
}
h3 {
	font-family: Arial, Helvetia, sans-serif;
	color: #71bf00;
	font-size: 150%;
  text-transform:uppercase;
}
p {
	line-height: 1.45em;
}
#home p {
	line-height: 1.4em;
}

strong {
	color: #71bf00;
}

th, td {
    padding: .3em .5em;
}
td.year {
	color: #71bf00;
	vertical-align: top;
}

.visible-xs ul,
.pane-node ul {
/*	list-style-image: url('../../my_bootstrap/images/pfeil_r.png'); */
	list-style-type:none;
	margin-left: -2.8em;
}
.node-type-internal-page .content ul {
  list-style-type: disc;
}
.node-type-internal-page .content li {
  margin-left: 1.2em;
}
.node-type-internal-page .content ul ul {
  list-style-type: circle;
}
.content ul {
	margin-left:0;
}

.pfeil {
	padding-right:1.0em;
	color: #71bf00;
}

.footer {
  color: #3e3e3e;
  height: 6.15em;	
  border: none;
  padding: 2em 0em 0em 0em;
  background-color: #ffffff;
  margin-top: 0;
/*   background:url('../../images/hilger_footer.png') no-repeat top left;} */
}
#page-top-ref {
	float: right;
}

.secondary-links {
	padding-right: 6.7em;
}
.homepanel {
    border: solid #cccccc 0.1em;
	padding: 0em 0.75em 0em 0.75em;
	margin-bottom: 0.9em;
}

.home-panel-row1 .main-content,
.home-panel-row2 .main-content{
	height: 88%;
}
.home-panel-row1 .links_intern,
.home-panel-row2 .links_intern{
  height: 11%;
}

.home-panel-row3 .main-content {
  height: 75%;
}
.homepanel .main-content img {
  padding-top:0.75em;
}
.home-panel-row3 .links_intern {
  height: 25%;
}

.home-panel-row1 {
	//min-height: 31em;
}
.pane-content,
.pane-content .node,
.pane-content .node .clearfix,
.pane-content .node .clearfix .content {
  height: 100%;
}	
	
.pane-content .node .clearfix .content {
  padding-top:0.75em;
}
.pane-contact {
    margin-top: 2em;
}
.home-panel-row2 {
  //min-height: 10em;
}
img.mvv {
    min-width: 35px;
    max-width: 70px;
}
.img1, .img2 {
	margin-right: 1em;
}
@media (min-width: @screen-lg-min) {
.img1.visible-lg,
.img2.visible-lg {
    display: inline !important;
}
}
.team-col-1 {
	padding-top:1.5em;
}

.secondary a:link {
  color: #3e3e3e;
  text-decoration: none;
}


.secondary a:visited {
  color: #3e3e3e;
  text-decoration: none;
}

#nav .vlist a:link {
  color: #3e3e3e;
  text-decoration: none;
}
#nav .vlist a:visited {
  color: #3e3e3e;
  text-decoration: none;
}

a:focus {
	outline-style: none;
}

.secondary a.active {
  color: #008bd0;
  text-decoration: none;
}
.secondary span.links a:hover {
  color: #008bd0;
  text-decoration: none;
}
.secondary span.links a:focus {
  color: #008bd0;
  text-decoration: none;
}
#nav .vlist li a.active {
  color: #008bd0;
  text-decoration: none;
}
#nav .vlist li a:hover {
  color: #008bd0;
  text-decoration: none;
}
#nav .vlist li a:focus {
  color: #008bd0;
  text-decoration: none;
}

.mail_intern a.spamspan,
a.link_homepage,
a.link_extern {
  color: #008bd0;
  text-decoration: none;  
}
a.link_extern {
  color: #3e3e3e;
  text-decoration: none;  
}
a.link_extern:hover {
  color: #008bd0; 
}

.link_intern:link,
.link_intern:visited,
.link_intern:hover,
#page-top-ref:link,
#page-top-ref:visited,
#page-top-ref:hover {
  color: #008bd0;
  padding-right: 1.2em;
  text-decoration: none;
}
.link_intern_back:link,
.link_intern_back:visited,
.link_intern_back:hover {
  color: #008bd0;
  padding-right: 0.6em;
  text-decoration: none;
}

.phone {
	color: #71bf00;
}

.subcolumns h2 {
  margin: 0;	
}

.bootstrap-twocol-stacked .row .left {
  padding-top: 2em;

}
.c33r {
  padding-top: 1em;
	margin-left: 0em;
	width: 33.3%;
}

.bootstrap-twocol-stacked .row .right h3 {
	padding-bottom: 0;
	 padding-top: 0.3em;
}

#home .c33l,
#home .c33r {
  padding: 0em;
 
}
.c33r .subcr,
.c33l .subc {
	border-top: solid #71bf00 0.17em;
}
#home .c33r .subcr,
#home .c33l .subc {
  border: none;
}
.c33l .subcl {
	border-top: solid #ffffff 0.17em;
}
.bootstrap-twocol-stacked .row .left.col-md-push-4,
.bootstrap-twocol-stacked .row .right {
  padding-top: 0.8em;
	border-top: solid #71bf00 0.17em;
}
.bootstrap-twocol-stacked .row .right.col-md-pull-8 {
    border-top: none;
}
#contact-mail-page {
	padding-bottom: 1.15em;
}
.noborder tbody {
	border-top:none;
}

li p {
	margin-bottom:0.5em;
}
.pane-content ul li {
	text-indent: -2em; 
	padding-left: 1.5em;
}
.location-locations-display {
    display: none;
}
.row2-3col .row1
.row2-3col .row1{
/*
 display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
  */

}
.row2-3col .row1 > [class*='col-'],
.row2-3col .row2 > [class*='col-'] {
/*
  display: flex;
   flex-direction: column;

	
*/
}
#mvv_widget_wrap {
    max-width: 400px;
}
#mvv_widget_wrap img {
    width: auto;
}


/*
<div class="gotop"><p><a href="#page_top" id="page-top-ref">NACH OBEN ▲ </a></p></div>
*/